import { motion } from 'framer-motion';
import './App.css';

function App() {
  return (
    <div className='info-page' id='info'>
      <motion.div
      initial={{ opacity: 1, y: '100vw' }}
      animate={{ opacity: 1, y:'0' }}  
      transition={{ duration: 1 }}>
        
        <div className="MainInfo">

          <motion.div
          initial={{ opacity: 0, height: '700px', width: '700px' }}
          animate={{ opacity: 1, height: '100vh', width: '100vw' }}  
          transition={{ duration: 1 }}>

              
          <div className="ripple ripple1"></div>
          <div className="ripple ripple2"></div>
              
          </motion.div>

          <div className="login-page-form" id="loginloginform">
            <div className="login-page-title">
                <h2>Cicero - Simplify Your Student Life</h2>
            </div>
            <div className='descclass'>
              <h4 className='description'>
                Launching soon, Cicero is the all-in-one digital hub built to streamline your academic journey. From managing notes and assignments to organizing your schedule and daily tasks, Cicero equips you with every tool you need to stay ahead. No more juggling between multiple apps—Cicero integrates your to-do lists, calendars, and even real-time weather updates, all in one intuitive platform.

                Designed with students in mind, Cicero is your go-to solution for staying organized, boosting productivity, and achieving your goals. Whether you’re on the go or at your desk, Cicero brings structure and clarity to your day, so you can focus on what truly matters.
              </h4>
              <h6>
                Join us soon and experience the future of student organization. Stay connected for updates!
              </h6>
            </div>
          </div>

        </div>
      </motion.div>
    </div>
  );
}

export default App;
